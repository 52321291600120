

























import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import MultipassSessionCard from "@/components/multipass/MultipassSessionCard.vue"
import Vue from "vue"

@Component({
  components: { MultipassSessionCard },
})
export default class MultipassDetail extends Vue {
  @(Prop()!) multipassConfig: any

  navigateToSelectSeats() {
    this.$router.push({
      name: "multipass-session",
      params: {
        lookupRef: this.multipassConfig.lookup_ref,
        sessionId: this.multipassConfig.multipass_sessions[0].id,
      },
      query: this.$defaultQuery(),
    })
  }
}
