




































import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"
import filters from "@/filters"

@Component({
  filters: filters,
})
export default class SessionCardMP extends Vue {
  @Prop() multipass_config!: any
  @Prop() multipass_session!: any
  @Prop() index!: number

  sessionClick() {
    let mp_session_id = this.multipass_config.multipass_sessions[0].id

    if (!this.multipass_config.is_fixed_seat_mode) {
      mp_session_id = this.multipass_session.id
    }

    this.$router.push({
      name: "multipass-session",
      params: {
        lookupRef: this.multipass_config.lookup_ref,
        sessionId: mp_session_id,
      },
      query: this.$defaultQuery(),
    })
  }
}
